@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(/assets/font/OpenSans.woff) format('woff');
  } */

@font-face {
  font-family: "Roboto-Light";
  src: local("Roboto-Light"),
    url("./assets/fonts/Roboto-Light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto-Bold"),
    url("./assets/fonts/Roboto-Bold.woff") format("woff");
  font-weight: bold;
}
@font-face {
  font-family: "Roboto-Medium";
  src: local("Roboto-Medium"),
    url("./assets/fonts/Roboto-Medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url("./assets/fonts/Roboto-Regular.woff") format("woff");
  font-weight: 400;
}
$Roboto_Light: 300;
$Roboto_Regular: 400;
$Roboto_Medium: 500;
$Roboto_Bold: 600;
/* @import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700"); */

body,
div,
label,
input,
textarea,
table,
th,
td {
  font-family: "Roboto-Regular" !important;
}
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: #f8f8fa;
}
.d-flex {
  display: flex;
}
.d-flex-a {
  display: flex;
  align-items: center;
}
.d-flex-j {
  display: flex;
  justify-content: center;
}
.d-flex-ja {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-column {
  flex-direction: column;
}
.header {
  background-color: white;
  height: 76px;
  padding: 0 20px;
  position: fixed;
  right: 0;
  top: 0;
  left: 100px;
  z-index: 100; // lots of model design view is not properly
  box-shadow: 0 1px 1px 0px rgba(9, 30, 66, 0.12);
}
// Sidebat start

.sidebar {
  flex: 0 0 100px !important;
  max-width: 100px !important;
  min-width: 100px !important;
  width: 100px !important;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 8px;
    cursor: pointer;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: none;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #dadada;
    border-radius: 8px;
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.admin-sidebar {
  background: linear-gradient(180deg, #24142e 0%, #070d26 100%),
    linear-gradient(180deg, #3c0f56 0%, #19457d 100%) !important;
  border-right: 0.2px solid #c3c3c35c;
}
.admin-body {
  background: linear-gradient(180deg, #24142e 0%, #070d26 100%),
    linear-gradient(180deg, #3c0f56 0%, #19457d 100%) !important;
  &.ant-layout-footer {
    color: #ffffff;
    min-height: 11.3vh;
  }
  &.ant-layout-header {
    border-bottom: 0.2px solid #c3c3c35c;
    .search-box {
      color: #ffffff;
    }
    .arrow-block {
      display: none !important;
    }
    .arrow-none {
      display: block !important;
    }
    .version {
      color: #fff;
      text-decoration: underline solid #fff !important;
    }
  }
  &.maincontent {
    min-height: 88.7vh;
  }
}
.maincontent {
  min-height: 88.7vh;
}
.ant-layout-footer {
  min-height: 11.3vh;
}
.arrow-block {
  display: block;
}
.arrow-none {
  display: none;
}
.companyadmin-sidebar {
  background: linear-gradient(180deg, #1f082d 0%, #0f315d 100%) !important;
}
.manager-sidebar {
  background: linear-gradient(180deg, #3c0f56 0%, #19457d 100%) !important;
}
.employee-sidebar {
  background: linear-gradient(180deg, #19457d 0%, #3c0f56 100%) !important;
}
.filter-btn {
  height: 40px;
  width: 92px;
  cursor: pointer;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  //background-color: #FFFFFF;
  .f-title {
    font-weight: 500;
  }
  .ml-1 {
    margin-left: 0.4rem;
  }
}
//  sidebar End
.ant-layout-has-sider {
  .ant-layout {
    margin-left: 100px;
    background-color: #f8f8fa;
  }
  .ant-layout-footer {
    background-color: #f8f8fa;
  }
}
.maincontent {
  padding: 7rem 2.4rem 0rem 2.4rem;
}
//modal common css
.ant-modal-content {
  border-radius: 10px !important;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 18, 47, 0.39);
}
.ant-modal-header {
  border-radius: 10px 10px 0 0 !important;
}
.ant-modal-body {
  padding: unset !important;
}
//model buttom css
.m-cancel-btn {
  height: 49px;
  width: 113px;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  background-color: #ffffff;
  color: #241a2e;
  font-size: 17px;
  font-weight: bold;
  &:hover {
    background-color: #ffffff;
    color: #241a2e;
    border: 1px solid #cbcbcb;
  }
}
.m-save-btn {
  height: 48px;
  min-width: 112px;
  border-radius: 4px;
  background-color: #0062ff;
  color: #ffffff;
  font-size: 17px;
  font-weight: bold;
  &:hover {
    background-color: #0062ff;
    color: #ffffff;
    border: unset;
  }
}
.custom-modal-body {
  background-color: #f8f8fa;
  padding: 0.5rem 1.3rem;
  label {
    color: #241a2e !important;
    font-size: 14px !important;
    //font-weight: $Roboto_Bold !important;
    font-family: "Roboto-Medium" !important;
    margin-bottom: 0.4rem;
  }
  .mb-5 {
    margin-bottom: 0.5rem;
  }
  .mb-1 {
    margin-bottom: 0.7rem;
  }
  .pl-3 {
    padding-left: 0.5rem;
  }
  .mb-0 {
    margin-bottom: 0px;
  }
  .mt-3 {
    margin-top: 1rem;
  }
  .custom-textarea {
    height: 110px;
    border: 1px solid #d8d8d8;
    background-color: #ffffff;
    padding: 1rem;
    textarea {
      width: 100%;
      border: none;
      resize: none;
    }
  }
  textarea {
    padding: 1rem;
  }
  .split-box {
    display: flex;
    flex-wrap: wrap;
    .pl-3 {
      padding-left: 0.5rem;
    }
    .w-100 {
      width: 100%;
    }
    .pr-3 {
      padding-right: 0.5rem;
    }
    .flex-4 {
      flex: 4;
    }
  }
}
.custom-ant-btn {
  height: 40px;
  min-width: 113px;
  border-radius: 4px;
}
.modal-title {
  color: #241a2e;
  font-size: 27px;
  font-weight: bold;
}
.modal-dis {
  color: #b1b4be;
  font-size: 16px;
  padding-top: 0.5rem;
}
.required {
  color: red;
}
.custom-select {
  width: 100%;
  .ant-select-selector {
    height: 100% !important;
    min-height: 40px;
    display: flex;
    align-items: center;
    border-radius: 5px !important;
  }
}
.ant-form-item-label {
  text-align: left !important;
  label {
    //font-weight: $Roboto_Bold;
    font-family: "Roboto-Medium" !important;
  }
}
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: "";
}
.ant-form-item-label > label::after {
  content: "";
}
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  font-weight: 500 !important;
  content: "*";
}
.ant-checkbox,
.ant-checkbox-input,
.ant-checkbox-inner {
  height: 24px !important;
  width: 24px !important;
  border-radius: 4px !important;
}
.ant-checkbox-checked::after {
  background-color: #46cc55;
  border: unset;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  z-index: 1;
  position: absolute;
  left: 28.5% !important;
  border: 2px solid #fff !important;
  border-top: 0 !important;
  border-left: 0 !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #46cc55;
}
.ant-radio-wrapper {
  align-items: center !important;
  .ant-radio-inner::after {
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #47c98f;
  }
  .ant-radio-inner {
    width: 26px;
    height: 26px;
    border-width: 2px !important;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #47c98f;
  }
  .ant-radio-checked::after {
    border: 1px solid #47c98f !important;
  }
  .ant-radio {
    top: 0px !important;
  }
}
.ant-empty-normal {
  margin: unset !important;
}
.ant-picker-suffix {
  width: 30px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  .anticon-calendar {
    width: 30px;
    height: 25px;
    svg {
      display: none;
    }
    /* inline SVG */
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMThweCIgaGVpZ2h0PSIxOHB4IiB2aWV3Qm94PSIwIDAgMTggMTgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+Q2FsZW5kYXI8L3RpdGxlPgogICAgPGcgaWQ9IkNhbGVuZGFyIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cGF0aCBkPSJNMTMsMCBDMTMuNTUyMjg0NywtMS4wMTQ1MzA2M2UtMTYgMTQsMC40NDc3MTUyNSAxNCwxIEwxNCwyIEwxNiwyIEMxNy4xMDQ1Njk1LDIgMTgsMi44OTU0MzA1IDE4LDQgTDE4LDE2IEMxOCwxNy4xMDQ1Njk1IDE3LjEwNDU2OTUsMTggMTYsMTggTDIsMTggQzAuODk1NDMwNSwxOCAxLjM1MjcwNzVlLTE2LDE3LjEwNDU2OTUgMCwxNiBMMCw0IEMtMS4zNTI3MDc1ZS0xNiwyLjg5NTQzMDUgMC44OTU0MzA1LDIgMiwyIEw0LDIgTDQsMSBDNCwwLjQ0NzcxNTI1IDQuNDQ3NzE1MjUsMS4wMTQ1MzA2M2UtMTYgNSwwIEM1LjU1MjI4NDc1LC0xLjAxNDUzMDYzZS0xNiA2LDAuNDQ3NzE1MjUgNiwxIEw2LDIgTDEyLDIgTDEyLDEgQzEyLDAuNDQ3NzE1MjUgMTIuNDQ3NzE1MywxLjAxNDUzMDYzZS0xNiAxMywwIFogTTE1LDYgTDMsNiBDMi40NDc3MTUyNSw2IDIsNi40NDc3MTUyNSAyLDcgTDIsNyBMMiwxNSBDMiwxNS41NTIyODQ3IDIuNDQ3NzE1MjUsMTYgMywxNiBMMywxNiBMMTUsMTYgQzE1LjU1MjI4NDcsMTYgMTYsMTUuNTUyMjg0NyAxNiwxNSBMMTYsMTUgTDE2LDcgQzE2LDYuNDQ3NzE1MjUgMTUuNTUyMjg0Nyw2IDE1LDYgTDE1LDYgWiIgaWQ9IkNvbWJpbmVkLVNoYXBlIiBmaWxsPSIjMjQxQTJFIj48L3BhdGg+CiAgICA8L2c+Cjwvc3ZnPg==");
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 50%;
    border-radius: 2px;
    border: none;
    color: #241a2e;
    //padding: 10px 30px 10px 10px;
    // disable default appearance
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }
}
//tabs
.ant-tabs-tab {
  padding: 8px 0 12px 0 !important;
}
.ant-tabs {
  overflow: visible;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: unset !important;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 18px !important;
}
.ant-tabs-tab-btn {
  color: #b1b4be !important;
}
form {
  label {
    // color: #241A2E !important;
    font-size: 14px;
    //font-weight: $Roboto_Bold !important;
    font-family: "Roboto-Medium" !important;
    //margin-bottom: 0.4rem;
  }
  .mb-07 {
    margin-bottom: 0.7rem;
  }
  .mb-05 {
    margin-bottom: 0.5rem;
  }
  .ant-input {
    border-radius: 5px !important;
  }
  .custom-select {
    width: 100%;
    .ant-select-selector {
      height: 100% !important;
      min-height: 40px;
      display: flex;
      align-items: center;
      border-radius: 5px !important;
    }
  }
  .w-100 {
    width: 100%;
  }
  .custom-textarea {
    height: 110px;
    border: 1px solid #d8d8d8;
    background-color: #ffffff;
    padding: 1rem;
    textarea {
      width: 100%;
      border: none;
      resize: none;
    }
  }
  .split-box {
    display: flex;
    flex-wrap: wrap;
    .pl-3 {
      padding-left: 0.5rem;
    }
    .w-100 {
      width: 100%;
    }
    .pr-3 {
      padding-right: 0.5rem;
    }
    .flex-4 {
      flex: 4;
    }
  }
}
.required {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  font-weight: 500 !important;
}
// Loader
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 768px) {
  .ant-table-wrapper {
    overflow-x: auto;
  }
}
.ant-table-column-title {
  font-family: "Roboto-Bold";
}
.ant-checkbox-indeterminate {
  .ant-checkbox-inner::after {
    background-color: unset !important;
  }
}
.form-control {
  width: 100% !important;
  height: 40px !important;
}


.rich-text-editor {
  background-color: #ffffff;
  color: #241a2e;
  font-size: 16px;
  font-family: "Roboto-Light !important";
}

.rich-text-wrapper {
  border: 2px solid #bfbfbf66;
  border-radius: 4px;
  background-color: #ffffff;
  color: #241a2e;
  font-size: 16px;
  font-family: Roboto-Light !important;
}

.rich-text-wrapper-agenda { 
  border: 1px solid #5bcae4;
  border-radius: 4px;
  background-color: #ffffff;
  color: #241a2e;
  font-size: 16px;
  font-family: Roboto-Light !important;
}

.DraftEditor-root {
  margin: 1em;
}


.public-DraftStyleDefault-ltr {
  margin: 0 !important;
}