.side-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;

    //min-height: 600px;
    .normal-menu {
        cursor: pointer;
        height: 90px;
        width: 90px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .icons {
            width: 90px;
            height: 70px;
            color: #D4D1D7;
        }

        #question-icon {
            padding: 15px;
        }

        .expand {
            width: 30px;
            height: 30px;
        }

        .pt-01 {
            padding-top: 1rem;
        }
    }
    .highlighted-menu-item { 
        background-color: #8D22E6;
        border-radius: 10px;
    }

    .highlighted-menu-item-2 { 
        background-color: #483D50;
        border-radius: 10px;
    }

    .highlighted-menu-item-3 { 
        background-color: #8D22E6;
        border-radius: 10px;
    }

    .highlighted-menu-item-4 { 
        background-color: #10CDE8;
        border-radius: 10px;
    }

    .nonhighlighted-menu-item:hover { 
        background-color: rgba(255, 255, 255, 0.303);
        border-radius: 10px;
    }

    .menu-label { 
        height: 40px;
        width: 90px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-top: -20px;

        h5 { 
            color:#D4D1D7;
            text-align: center;
        }
    }

    .org-img {
        height: 48px;
        width: 48px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        cursor: pointer;
        background-color: #5bcae4;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 16px;
        color: white;
        margin-bottom: 2vh;
    }

    .org-name {
        color: white;
        text-align: center;
        font-size: 1vw;
        margin-bottom: 1rem;
    }
}