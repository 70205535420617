.header-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 76px;
  width: 100%;
  .search-box {
    position: relative;
    img {
      position: absolute;
      top: 25px;
      left: 8px;
      color: lightgray;
      width: 16px;
      height: 16px;
    }
    input {
      height: 40px;
      width: 258px;
      border: 1px solid #d8d8d8;
      border-radius: 10px;
      padding-left: 2rem !important;
      background-color: unset !important;
      cursor: pointer;
      &::placeholder {
        color: #b1b4be;
      }
      &:focus-visible {
        outline: unset;
      }
    }
  }
  .version {
    margin-right: 1rem;
    text-decoration: underline solid #241a2e;
    cursor: pointer;
  }
  .role-btn {
    height: 40px;
    //min-width: 182px;
    min-width: 93px;
    border: 1px solid #ebebeb;
    border-radius: 20px;
    background-color: #f8f8fa;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    .ant-radio-button-wrapper {
      background-color: unset;
      span {
        color: #bbbbbb;
        font-size: 13px;
        font-weight: bold;
      }
    }
    .ant-radio-button-wrapper::before {
      background-color: unset !important;
    }
    .ant-radio-button-wrapper:first-child {
      &.active-btn {
        color: #8d22e6 !important;
        span {
          color: #8d22e6 !important;
        }
      }
    }
    .ant-radio-button-wrapper:nth-child(2) {
      &.active-btn {
        color: #5bcae4 !important;
        span {
          color: #5bcae4 !important;
        }
      }
    }
    .ant-radio-button-wrapper:nth-child(3) {
      &.active-btn {
        color: #241a2e !important;
        min-width: 78px !important;
        max-width: 78px !important;
        span {
          color: #241a2e !important;
        }
      }
    }
    .active-btn {
      height: 36px !important;
      min-width: 90px !important;
      border-radius: 18px !important;
      background-color: #ffffff !important;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5) !important;
      font-size: 13px;
      font-family: "Roboto-Bold" !important;
      display: flex;
      align-items: center;
      justify-content: center;
      /*  span{
              color: #8D22E6;
              font-weight: bold;
            } */
    }
    .ant-radio-button-wrapper {
      height: 36px;
      border-radius: 18px;
      background-color: unset;
      box-shadow: unset;
      border: unset;
      display: flex;
      align-items: center;
    }
  }
  .profile-menu-option {
    position: relative;
    .anticon {
      cursor: pointer;
    }
    .tooltip-hover {
      div {
        line-height: 18px;
        margin: 0.5rem;
        cursor: pointer;
      }
      display: none;
      position: absolute;
      //bottom: -268%;
      // right: 0%;
      right: -21%;
      top: 100%;
      // background: white;
      color: #ffffff;
      height: 80px;
      width: 180px;
      border-radius: 16px;
      background-color: rgba(36, 26, 46, 0.9);
      box-shadow: 0 2px 4px 0 rgba(36, 26, 46, 0.2);
      padding: 0.5rem 1rem 0.5rem 1rem;
      z-index: 1000000;
      text-align: left;
      &:before {
        content: "";
        display: block;
        position: absolute;
        right: 6%;
        bottom: 98%;
        width: 0;
        height: 0;
        border: 9px solid transparent;
        border-bottom-color: rgba(36, 26, 46, 0.9);
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 6%;
        bottom: 98%;
        width: 0;
        height: 0;
        border: 9px solid transparent;
        border-bottom-color: rgba(36, 26, 46, 0.9);
      }
    }
    .tooltip-active {
      display: block !important;
    }
    .profile-img {
      height: 48px;
      width: 48px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      cursor: pointer;
      background-color: #5bcae4;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 16px;
      color: white;
      margin-right: 0.5rem;
    }
  }
}
